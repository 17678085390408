import {TakipTalebiEntity} from "@/entity/TakipTalebiEntity";
import {BorcluEntity} from "@/entity/BorcluEntity";
import {AlacakliEntity} from "@/entity/AlacakliEntity";
import {IcraDairesiEntity} from "@/entity/IcraDairesiEntity";
import {TakipDurumu} from "@/enum/TakipDurumu";
import {TahsilatEntity} from "@/entity/TahsilatEntity";
import {TakipEtiketiEntity} from "@/entity/TakipEtiketiEntity";
import {TakipEkAlanEntity} from "@/entity/TakipEkAlanEntity";
import {NotEntity} from "@/entity/NotEntity";
import {TaksitHesapAyarEntity} from "@/entity/TaksitHesapAyarEntity";
import {FileEntity} from "@/entity/FileEntity";
import IhtiyatiHacizEntity from "@/entity/IhtiyatiHacizEntity";
import {SatisTalebiEntity} from "@/entity/SatisTalebiEntity";
import {TakipTarihliKurEntity} from "@/entity/TakipTarihliKurEntity";
import AlacakKalemiEntity from "@/entity/AlacakBelgeler/AlacakKalemiEntity";


export class TakipEntity {
    id!: number;
    alacaklilar: Array<AlacakliEntity> = [];
    alacaklinin_talep_ettigi_hak!: string;
    alacakKalemleri: Array<AlacakKalemiEntity>=[];
    aktar_takip_kart_no!:number
    takipTalebi!:TakipTalebiEntity
    borclular: Array<BorcluEntity> = [];
    dosya_esas_no!: string;
    etiketler: Array<TakipEtiketiEntity> = [];
    file: Array<FileEntity> = [];
    hesap_yapilsin_mi!: boolean;
    icra_dairesi!: IcraDairesiEntity;
    icra_dairesi_id!: number | null;
    ihtiyati_haciz !:IhtiyatiHacizEntity;
    kalan_borc!: any;
    kapanis_tarihi!: Date;
    kurum_dosya_numarasi!:string|number
    mts_esas_no !: string;
    mts_takibi: boolean = false;
    notlar: Array<NotEntity> = [];
    ozel_icra_dairesi_isim!:string
    tahsil_harci_orani!: any;
    tahsilatlar: Array<TahsilatEntity> = [];
    takip_durumu!: TakipDurumu;
    takip_ek_alanlari: Array<TakipEkAlanEntity> = [];
    takip_tarihi!: Date|any;
    takip_tarihli_kur!: Array<TakipTarihliKurEntity>;
    takip_toplami!: string;
    taksit_hesap_ayar!: TaksitHesapAyarEntity;
    taksitler!: Array<any>;
    talepler: Array<TakipTalebiEntity> = [];
    tl_takibi: boolean = false; // TODO: seboya sor
    tuketici_kodu!:number|string
    uyap_dosya_id!: number;
    vekalet_ucreti_dortte_uc_mu: boolean = false;
    satis_talepleri!: Array<SatisTalebiEntity>;
}
